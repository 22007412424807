<template>
  <div>
    <b-row v-if="userType === 'TA'">
      <b-col class="my-2">
        <b-button variant="primary" :to="`/tracings/add`" class="float-right">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span  class="align-middle">{{$t("Labels.Add")}}</span>
        </b-button>
      </b-col>
    </b-row>
    <div id="data-list-list-view" class="data-list-container mt-2 px-">
      <b-table hover :fields="fields" :items="response" :responsive="true" tbody-tr-class="shadow-sm" table-variant="light">
        <template #cell(created_at)="item">
          <td>
            <listItem
              :value="item.value.split(' ')[0]"
              :description="item.value.split(' ')[1]"
              img="fecha"
            />
          </td>
        </template>
        <template #cell(name)="name">
          <td>
            <listItem
            :value="name.value"
            :description="$t('Labels.Name')"
            img="label"
            />
          </td>
        </template>
        <template #cell(description)="description">
          <td>
            <listItem
            :value="description.value"
            :description="$t('Labels.Description')"
            img="card-text"
            />
          </td>
        </template>
        <template #cell(distance)="distance">
          <td>
            <listItem
              :value="distance.value"
              :description="$t('Labels.Distance')"
              unit="mt"
              img="distance"
            />
          </td>
        </template>
        <template #cell(tracing_uuid)="data">
            <div class="text-center">
            <b-button v-if="data.item.status !== 'F' && data.item.status !== 'P'" variant="success" class="btn-icon rounded-circle btn-option" @click="confirmChangeStatus(data.item.tracing_uuid,'P')" :title="$t('Labels.Play')" v-b-tooltip.hover="{ variant: 'success', placement: 'top' }">
              <feather-icon icon="PlayIcon" :width="18" :height="18"/>
            </b-button>
            <b-button v-if="data.item.status === 'P'" variant="primary" class="btn-icon rounded-circle btn-option" @click="confirmChangeStatus(data.item.tracing_uuid, 'S')" :title="$t('Labels.Pause')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
              <feather-icon icon="PauseIcon" :width="18" :height="18"/>
            </b-button>
            <b-button v-if="data.item.status === 'P'" variant="danger" class="btn-icon rounded-circle btn-option" @click="confirmChangeStatus(data.item.tracing_uuid, 'F')" :title="$t('Labels.Stop')" v-b-tooltip.hover="{ variant: 'danger', placement: 'top' }">
              <feather-icon icon="SquareIcon" :width="18" :height="18"/>
            </b-button>
            <b-button variant="primary" class="btn-icon rounded-circle btn-option" :to="`/tracings/map/${data.item.tracing_uuid}`" :title="$t('Labels.Map')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
              <feather-icon icon="MapIcon" :width="18" :height="18"/>
            </b-button>
            <b-button variant="primary" class="btn-icon rounded-circle btn-option" :to="`/tracings/members/${data.item.tracing_uuid}`" :title="$t('Labels.Members')" v-b-tooltip.hover="{ variant: 'primary', placement: 'top' }">
              <feather-icon icon="UsersIcon" :width="18" :height="18"/>
            </b-button>
            <b-button variant="danger" class="btn-icon rounded-circle btn-option" @click="confirmDelete(data.item.tracing_uuid)" :title="$t('Labels.Delete')" v-b-tooltip.hover="{ variant: 'danger', placement: 'top' }">
              <feather-icon icon="TrashIcon" :width="18" :height="18"/>
            </b-button>
            </div>
        </template>
      </b-table>
    </div>
    <div v-if="response.length == 0" class="noData">{{$t('Messages.NoData')}}</div>
  </div>
</template>

<script>
import request from '@/libs/request/index'
import {
  BButton,
  BRow,
  BCol,
  BTable
} from 'bootstrap-vue'
import listItem from '@core/spore-components/listItem/listItem.vue'

const DATA = {
  code: 200,
  data: {
    count: 2,
    rows: [
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df4',
        name: 'Familia',
        description: 'Mi familia',
        distance: 2
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330dd3',
        name: 'Amigos',
        description: 'Mis amigos',
        distance: 2
      },
      {
        uuid: 'cdfbacef-3540-41b5-aae3-448bcd330df5',
        name: 'Amigos 2',
        description: 'Mis amigos 2',
        distance: 2
      }
    ]
  },
  msg: 'Ok'
}

export default {
  components: {
    BButton,
    BRow,
    BCol,
    listItem,
    BTable
  },
  data () {
    return {
      response: [],
      DATA,
      uuid: this.$route.params.uuid,
      userType: '',
      fields: [
        { key: 'created_at', label: this.$t('Labels.CreatedAt') },
        { key: 'name', label: this.$t('Labels.Name') },
        { key: 'description', label: this.$t('Labels.Description') },
        { key: 'distance', label: this.$t('Labels.Distance') },
        { key: 'tracing_uuid', label: '', thStyle: { width: '18%' } }
      ]
    }
  },

  methods: {
    async getResponse () {
      // this.response = DATA.data.rows
      const params = {
        url: 'tracing',
        method: 'GET'
      }
      await request(params).then(response => {
        this.response = response.data.data.map((obj) => { return Object.assign(obj.tracing, { tracing_uuid: obj.tracing_uuid }) })
      }).catch(() => {
        this.response = []
      })
    },
    confirmDelete (uuid) {
      this.$swal({
        title: this.$t('Messages.ConfirmDeleteConvoy'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('Messages.Yes'),
        cancelButtonText: this.$t('Messages.Cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          const params = {
            url: `tracing/${uuid}`,
            method: 'DELETE'
          }
          await request(params).then(response => {
            this.getResponse()
          })
        }
      })
    },
    async confirmChangeStatus (uuid, status) {
      const params = {
        url: `tracing/${uuid}`,
        method: 'PUT',
        params: {
          status: status
        }
      }
      await request(params).then(response => {
        this.getResponse()
      })
    }
  },

  created () {
    this.getResponse()
    this.userType = this.$store.state.session.AppActiveUser().userRole
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/ListTracings.scss";
</style>
